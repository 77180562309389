import React,{useState} from "react";
import { Link as Link2 } from "react-router-dom";
import * as Unicons from '@iconscout/react-unicons'
import Lightbox from 'react-18-image-lightbox';
import '../../node_modules/react-18-image-lightbox/style.css';
import mpore from '../assets/projects/mpore.png'
import kcl from '../assets/projects/kcl.png'
import axon from '../assets/projects/axon.jpg'
import utc from '../assets/projects/utc.jpg'
import echurch from '../assets/projects/echurch.jpg'
import elayoneweb from '../assets/projects/elayoneweb.png'
import elayonedash from '../assets/projects/elayonedash.png'
import map from '../assets/projects/map.png'
import rp from '../assets/projects/rp.jpg'
import huska from '../assets/projects/huska.png'
import ability from '../assets/projects/ability.png'
import renerg from '../assets/projects/renerg.png'
import nyamurindacoffee from '../assets/projects/nyamurindacoffee.png'
import lapervenche from '../assets/projects/lapervenche.png'
const images = [
    echurch,
    map,
    utc,
    huska,
    mpore,
    axon,
    rp,
    kcl,
    ability,
    renerg,
    nyamurindacoffee,
    lapervenche,
];

export default function Projects(){
    const [photoIndex, setActiveIndex] = useState(0);
    const [isOpen, setOpen] = useState(false);


    const handleCLick = (index) => {
        setActiveIndex(index)
        setOpen(!isOpen);
    }

    return(
        <>
           <section className="relative md:py-24 py-16" id="project">
        <div className="container">
            <div className="grid grid-cols-1 pb-8 text-center">
                <h3 className="mb-6 md:text-2xl text-xl md:leading-normal leading-normal font-semibold">My Recently Work & Projects</h3>

            </div>

            <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 mt-8 gap-[30px]">
                <div className="relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
                    <img src={echurch} alt=""/>
                    <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                    <div className="absolute bottom-0 opacity-0 group-hover:opacity-100 m-6 transition-all duration-500">
                        <Link2 to="#!" className="text-white hover:text-[#38554D] font-semibold transition-all duration-500">eChurch Platform</Link2>
                        <span className="block text-sm text-slate-400">React JS, nodeJS and PostgreSQL</span>
                    </div>

                    <div className="absolute z-10 opacity-0 group-hover:opacity-100 top-1/2 -translate-y-1/2 right-0 left-0 mx-auto text-center transition-all duration-500">
                        
                        <Link2 to="#" onClick={() => handleCLick(0)} className="btn bg-[#38554D] hover:bg-[#6C8E8A] border-[#38554D] hover:border-[#6C8E8A] text-white btn-icon rounded-full lightbox"><Unicons.UilCamera width={16}/></Link2>
                    </div>
                </div>
                <div className="relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
                    <img src={elayonedash} alt=""/>
                    <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                    <div className="absolute bottom-0 opacity-0 group-hover:opacity-100 m-6 transition-all duration-500">
                        <Link2 to="#!" className="text-white hover:text-[#38554D] font-semibold transition-all duration-500">Elayone management system</Link2>
                        <span className="block text-sm text-slate-400">React JS, nodeJS and Mysql with tailwind css</span>
                    </div>

                    <div className="absolute z-10 opacity-0 group-hover:opacity-100 top-1/2 -translate-y-1/2 right-0 left-0 mx-auto text-center transition-all duration-500">
                        
                        <Link2 to="#" onClick={() => handleCLick(0)} className="btn bg-[#38554D] hover:bg-[#6C8E8A] border-[#38554D] hover:border-[#6C8E8A] text-white btn-icon rounded-full lightbox"><Unicons.UilCamera width={16}/></Link2>
                    </div>
                </div>
                <div className="relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
                    <img src={elayoneweb} alt=""/>
                    <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                    <div className="absolute bottom-0 opacity-0 group-hover:opacity-100 m-6 transition-all duration-500">
                        <Link2 to="#!" className="text-white hover:text-[#38554D] font-semibold transition-all duration-500">Elayone Music Website</Link2>
                        <span className="block text-sm text-slate-400">React JS, nodeJS and Mysql with tailwind css</span>
                    </div>

                    <div className="absolute z-10 opacity-0 group-hover:opacity-100 top-1/2 -translate-y-1/2 right-0 left-0 mx-auto text-center transition-all duration-500">
                        
                        <Link2 to="#" onClick={() => handleCLick(0)} className="btn bg-[#38554D] hover:bg-[#6C8E8A] border-[#38554D] hover:border-[#6C8E8A] text-white btn-icon rounded-full lightbox"><Unicons.UilCamera width={16}/></Link2>
                    </div>
                </div>
                
                <div className="relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
                    <img src={map} alt=""/>
                    <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                    <div className="absolute bottom-0 opacity-0 group-hover:opacity-100 m-6 transition-all duration-500">
                        <Link2 to="#!" className="text-white hover:text-[#38554D] font-semibold transition-all duration-500">Ride Share App</Link2>
                        <span className="block text-sm text-slate-400">React JS, nodeJS and PostgreSQL </span>
                    </div>

                    <div className="absolute z-10 opacity-0 group-hover:opacity-100 top-1/2 -translate-y-1/2 right-0 left-0 mx-auto text-center transition-all duration-500">
                    <Link2 to="#" onClick={() => handleCLick(1)} className="btn bg-[#38554D] hover:bg-[#6C8E8A] border-[#38554D] hover:border-[#6C8E8A] text-white btn-icon rounded-full lightbox"><Unicons.UilCamera width={16}/></Link2>
                    </div>
                </div>
                
                <div className="relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
                    <img src={utc} alt=""/>
                    <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                    <div className="absolute bottom-0 opacity-0 group-hover:opacity-100 m-6 transition-all duration-500">
                        <Link2 to="#!" className="text-white hover:text-[#38554D] font-semibold transition-all duration-500">UTC FX Trading App</Link2>
                        <span className="block text-sm text-slate-400">React Native, PHP Laravel and MySql</span>
                    </div>

                    <div className="absolute z-10 opacity-0 group-hover:opacity-100 top-1/2 -translate-y-1/2 right-0 left-0 mx-auto text-center transition-all duration-500">
                    <Link2 to="#" onClick={() => handleCLick(2)} className="btn bg-[#38554D] hover:bg-[#6C8E8A] border-[#38554D] hover:border-[#6C8E8A] text-white btn-icon rounded-full lightbox"><Unicons.UilCamera width={16}/></Link2>
                    </div>
                </div>
                
                <div className="relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
                    <img src={huska} alt=""/>
                    <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                    <div className="absolute bottom-0 opacity-0 group-hover:opacity-100 m-6 transition-all duration-500">
                        <Link2 to="#!" className="text-white hover:text-[#38554D] font-semibold transition-all duration-500">HUSKA Group website</Link2>
                        <span className="block text-sm text-slate-400">PHP Laravel</span>
                    </div>

                    <div className="absolute z-10 opacity-0 group-hover:opacity-100 top-1/2 -translate-y-1/2 right-0 left-0 mx-auto text-center transition-all duration-500">
                    <Link2 to="#" onClick={() => handleCLick(3)} className="btn bg-[#38554D] hover:bg-[#6C8E8A] border-[#38554D] hover:border-[#6C8E8A] text-white btn-icon rounded-full lightbox"><Unicons.UilCamera width={16}/></Link2>
                    </div>
                </div>
                
                <div className="relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
                    <img src={mpore} alt=""/>
                    <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                    <div className="absolute bottom-0 opacity-0 group-hover:opacity-100 m-6 transition-all duration-500">
                        <Link2 to="#!" className="text-white hover:text-[#38554D] font-semibold transition-all duration-500">Mpore Fashion E-commerce</Link2>
                        <span className="block text-sm text-slate-400">PHP Wordpress</span>
                    </div>

                    <div className="absolute z-10 opacity-0 group-hover:opacity-100 top-1/2 -translate-y-1/2 right-0 left-0 mx-auto text-center transition-all duration-500">
                    <Link2 to="#" onClick={() => handleCLick(3)} className="btn bg-[#38554D] hover:bg-[#6C8E8A] border-[#38554D] hover:border-[#6C8E8A] text-white btn-icon rounded-full lightbox"><Unicons.UilCamera width={16}/></Link2>
                    </div>
                </div>
                
                <div className="relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
                    <img src={axon} alt=""/>
                    <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                    <div className="absolute bottom-0 opacity-0 group-hover:opacity-100 m-6 transition-all duration-500">
                        <Link2 to="#!" className="text-white hover:text-[#38554D] font-semibold transition-all duration-500">AXON Tunga App</Link2>
                        <span className="block text-sm text-slate-400">React Native, PHP Laravel and MySql</span>
                    </div>

                    <div className="absolute z-10 opacity-0 group-hover:opacity-100 top-1/2 -translate-y-1/2 right-0 left-0 mx-auto text-center transition-all duration-500">
                    <Link2 to="#" onClick={() => handleCLick(5)} className="btn bg-[#38554D] hover:bg-[#6C8E8A] border-[#38554D] hover:border-[#6C8E8A] text-white btn-icon rounded-full lightbox"><Unicons.UilCamera width={16}/></Link2>
                    </div>
                </div>
                
                <div className="relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
                    <img src={rp} alt=""/>
                    <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                    <div className="absolute bottom-0 opacity-0 group-hover:opacity-100 m-6 transition-all duration-500">
                        <Link2 to="#!" className="text-white hover:text-[#38554D] font-semibold transition-all duration-500">RP Corporate Web</Link2>
                        <span className="block text-sm text-slate-400">React js, node JS and MongoDB</span>
                    </div>

                    <div className="absolute z-10 opacity-0 group-hover:opacity-100 top-1/2 -translate-y-1/2 right-0 left-0 mx-auto text-center transition-all duration-500">
                    <Link2 to="#" onClick={() => handleCLick(6)} className="btn bg-[#38554D] hover:bg-[#6C8E8A] border-[#38554D] hover:border-[#6C8E8A] text-white btn-icon rounded-full lightbox"><Unicons.UilCamera width={16}/></Link2>
                    </div>
                </div>
                
                <div className="relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
                    <img src={kcl} alt=""/>
                    <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                    <div className="absolute bottom-0 opacity-0 group-hover:opacity-100 m-6 transition-all duration-500">
                        <Link2 to="#!" className="text-white hover:text-[#38554D] font-semibold transition-all duration-500">Kigali Coffee E-commerce</Link2>
                        <span className="block text-sm text-slate-400">PHP Wordpress</span>
                    </div>

                    <div className="absolute z-10 opacity-0 group-hover:opacity-100 top-1/2 -translate-y-1/2 right-0 left-0 mx-auto text-center transition-all duration-500">
                    <Link2 to="#" onClick={() => handleCLick(7)} className="btn bg-[#38554D] hover:bg-[#6C8E8A] border-[#38554D] hover:border-[#6C8E8A] text-white btn-icon rounded-full lightbox"><Unicons.UilCamera width={16}/></Link2>
                    </div>
                </div>
                <div className="relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
                    <img src={ability} alt=""/>
                    <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                    <div className="absolute bottom-0 opacity-0 group-hover:opacity-100 m-6 transition-all duration-500">
                        <Link2 to="#!" className="text-white hover:text-[#38554D] font-semibold transition-all duration-500">Ability Counselling Website</Link2>
                    </div>

                    <div className="absolute z-10 opacity-0 group-hover:opacity-100 top-1/2 -translate-y-1/2 right-0 left-0 mx-auto text-center transition-all duration-500">
                    <Link2 to="#" onClick={() => handleCLick(7)} className="btn bg-[#38554D] hover:bg-[#6C8E8A] border-[#38554D] hover:border-[#6C8E8A] text-white btn-icon rounded-full lightbox"><Unicons.UilCamera width={16}/></Link2>
                    </div>
                </div>
                <div className="relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
                    <img src={renerg} alt=""/>
                    <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                    <div className="absolute bottom-0 opacity-0 group-hover:opacity-100 m-6 transition-all duration-500">
                        <Link2 to="#!" className="text-white hover:text-[#38554D] font-semibold transition-all duration-500">RENERG Rwanda Website</Link2>
                    </div>

                    <div className="absolute z-10 opacity-0 group-hover:opacity-100 top-1/2 -translate-y-1/2 right-0 left-0 mx-auto text-center transition-all duration-500">
                    <Link2 to="#" onClick={() => handleCLick(7)} className="btn bg-[#38554D] hover:bg-[#6C8E8A] border-[#38554D] hover:border-[#6C8E8A] text-white btn-icon rounded-full lightbox"><Unicons.UilCamera width={16}/></Link2>
                    </div>
                </div>
                <div className="relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
                    <img src={nyamurindacoffee} alt=""/>
                    <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                    <div className="absolute bottom-0 opacity-0 group-hover:opacity-100 m-6 transition-all duration-500">
                        <Link2 to="#!" className="text-white hover:text-[#38554D] font-semibold transition-all duration-500">Nyamurinda Coffee Website</Link2>
                    </div>

                    <div className="absolute z-10 opacity-0 group-hover:opacity-100 top-1/2 -translate-y-1/2 right-0 left-0 mx-auto text-center transition-all duration-500">
                    <Link2 to="#" onClick={() => handleCLick(7)} className="btn bg-[#38554D] hover:bg-[#6C8E8A] border-[#38554D] hover:border-[#6C8E8A] text-white btn-icon rounded-full lightbox"><Unicons.UilCamera width={16}/></Link2>
                    </div>
                </div>
                <div className="relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
                    <img src={lapervenche} alt=""/>
                    <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                    <div className="absolute bottom-0 opacity-0 group-hover:opacity-100 m-6 transition-all duration-500">
                        <Link2 to="#!" className="text-white hover:text-[#38554D] font-semibold transition-all duration-500">Lapervenche Nutrition Website</Link2>
                    </div>

                    <div className="absolute z-10 opacity-0 group-hover:opacity-100 top-1/2 -translate-y-1/2 right-0 left-0 mx-auto text-center transition-all duration-500">
                    <Link2 to="#" onClick={() => handleCLick(7)} className="btn bg-[#38554D] hover:bg-[#6C8E8A] border-[#38554D] hover:border-[#6C8E8A] text-white btn-icon rounded-full lightbox"><Unicons.UilCamera width={16}/></Link2>
                    </div>
                </div>
            </div>
        </div>
    </section>
        {isOpen && (
            <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                onCloseRequest={() => setOpen(false)}
                onMovePrevRequest={() =>
                setActiveIndex((photoIndex + images.length - 1) % images.length)
                }
                onMoveNextRequest={() =>
                setActiveIndex((photoIndex + 1) % images.length)
                }
            />
        )}

        </>
     
    )
}