import React from "react";
import { Link } from "react-router-dom";
import { TypeAnimation } from 'react-type-animation';
import CountUp from 'react-countup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

export default function HeroOne(){
    return(
        <>
           <section className="relative pt-28 personal-wrapper overflow-hidden bg-[#38554D]/5" id="home">
            <div className="absolute inset-0" id="overlay"></div>
            <div className="container">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                    <div>
                        <h4 className="font-bold lg:text-[40px] text-3xl lg:leading-normal leading-normal mb-4">Hey! I'm <br/>
                        <TypeAnimation
                            sequence={[
                                // Same substring at the start will only be typed out once, initially
                                'Bernard HAKIZIMANA',
                                1000, // wait 1s before replacing "Mice" with "Hamsters"
                                'FOUNDER & CEO, Enoveta Inc',
                                1000,
                                'Software Engineer',
                                1000,
                        
                            ]}
                            wrapper="span"
                            speed={50}
                            className="typewrite text-[#38554D]"
                            repeat={Infinity}
                        />
                        <span className="typewrite text-[#425c54]" data-period="2000"> <span className="wrap"></span> </span></h4>
                        <p className="text-slate-400 max-w-xl">FOUNDER & CEO at Enoveta inc, Software engineer deeply passionate about innovation.
                        </p>
                    
                        <div className="mt-6">
                            <Link target="blank" to="mailto:dadohbernard@gmail.com" className="btn bg-[#38554D]/10 hover:bg-[#38554D] border-[#38554D]/10 hover:border-[#38554D] text-[#38554D] hover:text-white rounded-md ms-1">Hire Me</Link>
                            <Link target="blank" to="/docs/Bernard%20HAKIZIMANA.pdf" className="btn bg-[#38554D]/10 hover:bg-[#38554D] border-[#38554D]/10 hover:border-[#38554D] text-[#38554D] hover:text-white rounded-md ms-1">My CV</Link>

                            <Link target="blank" to="https://wa.me/+250788917742?text=Hi!" className="btn bg-[#38554D]/10 hover:bg-[#38554D] border-[#38554D]/10 hover:border-[#38554D] text-[#38554D] hover:text-white rounded-md ms-1"><FontAwesomeIcon icon={faWhatsapp}  className="m-2" />Whatsapp</Link>
                        </div>
                    </div>

                    <div className="relative">
                        <img className="" src="/images/hero/hakizi.png" alt=""/>

                        <div className="absolute lg:bottom-10 md:bottom-10 bottom-2 ltr:md:-left-5 ltr:left-2 rtl:md:-right-5 rtl:right-2 p-4 rounded-lg shadow-md dark:shadow-gray-800 bg-white dark:bg-slate-900 m-3 w-44 text-center">
                            <span className="text-3xl font-medium mb-0"><span className="counter-value font-bold" data-target="125"> <CountUp
                                            start={1}
                                            className="counter-value"
                                            end={35}
                                            duration={2.75} /></span>+</span>
                            <h6 className="text-sm text-slate-400 mt-1">Project Completed</h6>
                        </div>

                        <div className="absolute lg:top-80 md:top-56 top-48 ltr:md:-right-0 ltr:right-2 rtl:md:-left-0 rtl:left-2 p-4 rounded-lg shadow-md dark:shadow-gray-800 bg-white dark:bg-slate-900 m-3 w-44 text-center">
                            <h6 className="font-semibold"> FullStack Dev.</h6>
                            <h6 className="text-sm text-slate-400 mt-1">6+ Years Experience</h6>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="relative">
            <div className="absolute block w-full h-auto bottom-[25px] z-1 left-0">
                <Link to="#about"><i className="mdi mdi-arrow-down absolute top-0 left-0 right-0 text-center inline-flex items-center justify-center rounded-full bg-white dark:bg-slate-900 h-12 w-12 mx-auto shadow-md dark:shadow-gray-800"></i></Link>
            </div>
        </div>
        </>
    )
} 