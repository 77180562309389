import * as Icon from 'react-feather';
export const expertiseData = [
    {
        title:'Developing',
        Icon: Icon.Monitor
    },
    {
        title:'Coffee',
        Icon: Icon.Coffee
    },
    {
        title:'Music',
        Icon: Icon.Music
    },
    {
        title:'Travel',
        Icon: Icon.Box
    },
    {
        title:'Worship',
        Icon: Icon.Target
    },
    {
        title:'Reading',
        Icon: Icon.Book
    },
    {
        title:'Designing',
        Icon: Icon.Aperture
    },
    {
        title:'Sport',
        Icon: Icon.Settings
    },
]
export const servicesData = [
    {
        title:'Web Application Development',
        desc:'Creating software applications that operate on web browsers. Includes frontend development for user interfaces, backend development for server-side logic, and database management. ',
        Icon: Icon.Airplay
    },
    {
        title:'Mobile Application Development',
        desc:'Creating software applications for smartphones and tablets. Involves designing, coding, testing, and deploying applications specifically tailored for iOS and Android mobile platforms',
        Icon: Icon.Aperture
    },
    {
        title:'USSD Application Development',
        desc:'I offer USSD solutions for seamless real-time communication between mobile phones and application servers. With USSD technology, users can access interactive services like mobile banking and balance inquiries, even without internet connectivity. ',
        Icon: Icon.Settings
    },
    {
        title:'API Integration ',
        desc:'API integration, enabling smooth communication and data exchange between your software systems. By connecting different applications, I enhance functionality and streamline processes, optimizing efficiency and performance',
        Icon: Icon.Compass
    },
    {
        title:'Web Design',
        desc:'I design websites using various technologies. This includes developing from scratch or utilizing platforms like WordPress, tailored to your needs.',
        Icon: Icon.Airplay
    },
    {
        title:'IT Support',
        desc:'I provide IT support services to ensure the smooth operation of your technology infrastructure. Whether you are facing technical issues, need software maintenance, or require system upgrades, I offer reliable assistance to keep your systems running efficiently.',
        Icon: Icon.Watch
    },

]
export const project = [
   {
    image: '/images/works/1.jpg',
    title:'Fronter Theme',
    subTitle: 'UI / UX Design',
   },
   {
    image: '/images/works/2.jpg',
    title:'Fronter Theme',
    subTitle: 'UI / UX Design',
   },
   {
    image: '/images/works/3.jpg',
    title:'Fronter Theme',
    subTitle: 'UI / UX Design',
   },
   {
    image: '/images/works/4.jpg',
    title:'Fronter Theme',
    subTitle: 'UI / UX Design',
   },
   {
    image: '/images/works/5.jpg',
    title:'Fronter Theme',
    subTitle: 'UI / UX Design',
   },
   {
    image: '/images/works/6.jpg',
    title:'Fronter Theme',
    subTitle: 'UI / UX Design',
   },
   {
    image: '/images/works/7.jpg',
    title:'Fronter Theme',
    subTitle: 'UI / UX Design',
   },
   {
    image: '/images/works/8.jpg',
    title:'Fronter Theme',
    subTitle: 'UI / UX Design',
   }
   
]
export const blogData = [
    {
        image:"/images/blog/01_giglink.jpg",
        title:'Giglink: Tailwind CSS NFT Marketplace Template',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        image:"/images/blog/01_techwind.jpg",
        title:'Techwind: Tailwind CSS Multipurpose Template',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        image:"/images/blog/01_upwind.jpg",
        title:'Upwind: Tailwind CSS Landing Page Template',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
]
export const review = [
    {
        name:'Thomas Israel',
        designation:'Manager',
        image:'/images/client/01.jpg',
        desc:"Obviously I'm a Web Designer. Web Developer with over 7 years of experience. Experienced with all stages of the development."
    },
    {
        name:'Thomas Israel',
        designation:'Manager',
        image:'/images/client/01.jpg',
        desc:"Obviously I'm a Web Designer. Web Developer with over 7 years of experience. Experienced with all stages of the development."
    },
    {
        name:'Thomas Israel',
        designation:'Manager',
        image:'/images/client/01.jpg',
        desc:"Obviously I'm a Web Designer. Web Developer with over 7 years of experience. Experienced with all stages of the development."
    },
    {
        name:'Thomas Israel',
        designation:'Manager',
        image:'/images/client/01.jpg',
        desc:"Obviously I'm a Web Designer. Web Developer with over 7 years of experience. Experienced with all stages of the development."
    },
    {
        name:'Thomas Israel',
        designation:'Manager',
        image:'/images/client/01.jpg',
        desc:"Obviously I'm a Web Designer. Web Developer with over 7 years of experience. Experienced with all stages of the development."
    },
]

