import React from "react";
export default function Experience(){
    return(
        <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="experience">
            <div className="container">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-6 md:text-2xl text-xl md:leading-normal leading-normal font-semibold">Work Experience</h3>

                    <p className="text-slate-400 max-w-xl mx-auto text-[15px]">
                    Obviously I'm a FullStack Developer. FullStack Developer with over 6 years of experience. Experienced with all kinds of software development.                    </p>
                </div>

                <div className="grid grid-cols-1 mt-8">
                    <div className="relative after:content-[''] after:absolute after:top-0 ltr:md:after:right-0 ltr:md:after:left-0 rtl:md:after:left-0 rtl:md:after:right-0 after:w-px after:h-full md:after:m-auto after:border-s-2 after:border-dashed after:border-gray-200 dark:after:border-gray-700 ms-3 md:ms-0">
                        
                        <div className="ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-[#38554D] md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 rtl:md:after:left-0 rtl:md:after:right-0 ltr:after:-left-9 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                            <div className="grid md:grid-cols-2">
                                <div className="md:text-end md:me-8 relative">
                                    <img src="/images/logos/enoveta.png" className="rounded-full h-9 w-9 md:ms-auto" alt=""/>
                                    <h5 className="my-2 font-semibold text-lg">Enoveta Inc</h5>
                                    <h6 className="text-sm mb-0">2023-Present</h6>
                                </div>

                                <div className="ltr:float-left rtl:float-right text-start md:ms-8 mt-6 md:mt-0">
                                    <h5 className="title mb-1 font-semibold">Software Engineer</h5>
                                    <p className="mt-3 mb-0 text-slate-400 text-[15px]">
                                    As a current Full Stack Developer, my role revolves around
utilizing Node.js, Express.js, React.js, JavaScript, HTML,
CSS, and PostgreSQL to architect and implement end-toend solutions. Responsibilities include crafting responsive
user interfaces, optimizing backend systems, and ensuring
efficient database performance</p>
                                </div>
                            </div>
                        </div>

                        <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-[#38554D] md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 ltr:after:-left-9 rtl:md:after:left-0 rtl:md:after:right-0 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                            <div className="grid md:grid-cols-2">
                                <div className="text-start md:ms-8 relative md:order-2">
                                    <img src="/images/logos/hhlinks.png" className="rounded-full h-9 w-9 md:me-auto" alt=""/>
                                    <h5 className="my-2 font-semibold text-lg">HH Links.</h5>
                                    <h6 className="text-sm mb-0">2021-2023</h6>
                                </div>

                                <div className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                                    <h5 className="title mb-1 font-semibold">Backend
Developer</h5>
                                    <p className="mt-3 mb-0 text-slate-400 text-[15px]">
                                    I was responsible for developing and maintaining serverside logic, managing the database architecture, and
creating RESTful APIs for seamless communication with the
frontend. I ensured secure authentication, implemented
middleware, and optimized backend performance.
Technologies: Node js, Express, Ejs, Php Laravel, MySQL,
PostgreSQL.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-[#38554D] md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 ltr:after:-left-9 rtl:md:after:left-0 rtl:md:after:right-0 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                            <div className="grid md:grid-cols-2">
                                <div className="md:text-end md:me-8 relative">
                                    <img src="/images/logos/renerg.png" className="rounded-full h-9 w-9 md:ms-auto" alt=""/>
                                    <h5 className="my-2 font-semibold text-lg">RENERG Rwanda.</h5>
                                    <h6 className="text-sm mb-0">2020-Now</h6>
                                </div>

                                <div className="ltr:float-left rtl:float-right text-start md:ms-8 mt-6 md:mt-0">
                                    <h5 className="title mb-1 font-semibold">Frontend
Developer</h5>
                                    <p className="mt-3 mb-0 text-slate-400 text-[15px]">
                                    
                                    As a Frontend Developer proficient in React.js, EJS,
JavaScript, HTML, and CSS, my primary responsibility
centered on crafting visually appealing and responsive user
interfaces. I focused on translating design concepts into
interactive web applications, optimizing performance, and
ensuring a seamless user experience.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="mt-12 ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-[#38554D] md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 ltr:after:-left-9 rtl:md:after:left-0 rtl:md:after:right-0 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                            <div className="grid md:grid-cols-2">
                                <div className="text-start md:ms-8 relative md:order-2">
                                    <img src="/images/logos/huzax.png" className="rounded-full h-9 w-9 md:me-auto" alt=""/>
                                    <h5 className="my-2 font-semibold text-lg">HUZAX Ltd</h5>
                                    <h6 className="text-sm mb-0">2018-2020</h6>
                                </div>

                                <div className="ltr:float-left rtl:float-right md:text-end md:me-8 mt-6 md:mt-0 md:order-1">
                                    <h5 className="title mb-1 font-semibold">Full-stack
Developer</h5>
                                    <p className="mt-3 mb-0 text-slate-400 text-[15px]">
                                    As a Full-stack Developer specializing in PHP, USSD and
Wordpress applications, my primary responsibility was
architecting and implementing end-to-end solutions. I
played a key role in designing and maintaining robust backend systems, ensuring seamless integration with front-end
components.
                                    
                                    </p>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </section>
    )
}