import React from "react";
import * as Unicons from "@iconscout/react-unicons"
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

export default function Footer(){
    return(
        <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
        <div className="py-[30px] px-0 border-t border-slate-800">
            <div className="container text-center">
                <div className="grid lg:grid-cols-12 md:grid-cols-3 grid-cols-1 items-center">
                    <div className="lg:col-span-3 md:text-start text-center">
                        <Link to="#" className="text-[22px] focus:outline-none">
                            <img src="/images/logo-light.png" className="mx-auto md:me-auto md:ms-0" alt=""/>
                        </Link>
                    </div>

                    <div className="lg:col-span-5 text-center mt-6 md:mt-0">
                        <p className="mb-0">© {new Date().getFullYear()} Bernard </p>
                    </div>

                    <ul className="lg:col-span-4 list-none md:text-end text-center mt-6 md:mt-0">
                        <li className="inline"><Link to="https://www.linkedin.com/in/hakizimanabernard" target="_blank" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-[#38554D] dark:hover:border-[#38554D] hover:bg-[#38554D] dark:hover:bg-[#38554D]"><Unicons.UilLinkedin width={16}/><i className="uil uil-linkedin" title="Linkedin"></i></Link></li>
                        <li className="inline"><Link to="https://www.facebook.com/dadoh.bernard" target="_blank" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-[#38554D] dark:hover:border-[#38554D] hover:bg-[#38554D] dark:hover:bg-[#38554D]"><Unicons.UilFacebookF width={16} /><i className="uil uil-facebook-f align-middle" title="facebook"></i></Link></li>
                        <li className="inline"><Link to="mailto:dadohbernard@gmail.com" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-[#38554D] dark:hover:border-[#38554D] hover:bg-[#38554D] dark:hover:bg-[#38554D]"><Unicons.UilEnvelope width={16}/></Link></li>
                        <li className="inline"><Link to="https://wa.me/+250788917742?text=Hi!" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-[#38554D] dark:hover:border-[#38554D] hover:bg-[#38554D] dark:hover:bg-[#38554D]"> <FontAwesomeIcon icon={faWhatsapp}  className="m-2" /></Link></li>

                    </ul>
                </div>
            </div>
        </div>
    </footer>
    )
}